import React, { FC } from "react"
import classnames from "classnames"

import GatsbyImage from "gatsby-theme-gaviscon/src/components/common/GatsbyImage"
import DangerouslySetInnerHtml from "gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml"

import useScreenRecognition from "gatsby-theme-gaviscon/src/hooks/useScreenRecognition"
import { TopBannerProps } from "gatsby-theme-gaviscon/src/components/TopBanner/model"
import "gatsby-theme-gaviscon/src/components/TopBanner/TopBanner.scss"
import "./TopBannerOverride.scss"

const TopBanner: FC<TopBannerProps> = ({
  title,
  description,
  image,
  imageAlt,
  mobileImage,
  mobileImageAlt,
  classes,
  imageStrategy,
  imageStyles,
  bannerBackground,
}) => {
  const { isMobile, isNotMobile } = useScreenRecognition()
  const cx = classnames("gs-top-banner", {
    [`${classes}`]: classes,
    [`gs-top-banner--background-${bannerBackground?.label}`]: bannerBackground?.label,
  })

  return (
    <div className={cx}>
      <div className="gs-top-banner__text">
        {title.length === 1 ? (
          <div className="gs-top-banner__text-title gs-hero-title">
            <DangerouslySetInnerHtml
              key={title[0].value}
              element="h1"
              className="gs-top-banner__text-line"
              html={title[0].value}
            />
          </div>
        ) : (
          <h1 className="gs-top-banner__text-title gs-hero-title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="gs-top-banner__text-line"
                html={value}
              />
            ))}
          </h1>
        )}

        {description ? (
          <DangerouslySetInnerHtml
            className="gs-top-banner__text-description"
            html={description}
          />
        ) : null}
      </div>
      {isMobile ? (
        <div className="gs-top-banner__image-mobile">
          {mobileImage?.fallbackUrl ? (
            <GatsbyImage
              className="gs-top-banner__image-img"
              fluid={mobileImage}
              useFocalPoint
              alt={mobileImageAlt}
              isLazyLoading={false}
              fadeIn={false}
            />
          ) : null}
        </div>
      ) : null}
      {isNotMobile ? (
        <div className="gs-top-banner__image-desktop">
          {image?.fallbackUrl ? (
            <GatsbyImage
              className="gs-top-banner__image-img"
              isLazyLoading={false}
              useFocalPoint
              fluid={image}
              imgStyle={imageStyles}
              objectFit={imageStrategy}
              alt={imageAlt}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default TopBanner
